// Définir l'URL de l'API des pays ici
const API_COUNTRY_URL = '/rest/shipping/countries'; // Tu peux ajuster l'URL si nécessaire
// resources/js/country.js

// Fonction pour récupérer les pays
export function fetchCountries() {
    return new Promise((resolve, reject) => {
        fetch(API_COUNTRY_URL)
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject(error));
    });
}

// Fonction pour gérer l'ouverture du menu et charger les pays
export function initCountrySelect() {
    return {
        open: false,
        countries: [],
        loading: false,

        // Méthode pour ouvrir le menu et charger les pays
        toggleMenu() {
            this.open = !this.open;

            // Si le menu est ouvert et que les pays ne sont pas encore chargés
            if (this.open && this.countries.length === 0) {
                this.loadCountries();
            }
        },

        // Méthode pour charger les pays
        loadCountries() {
            this.loading = true;
            fetchCountries()
                .then(data => {
                    this.countries = data;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    console.error("Erreur lors du chargement des pays");
                });
        },

        // Méthode pour mettre à jour le pays sélectionné
        updateCountry(countryId) {
            fetch('/rest/update-country', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ country_id: countryId })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        window.location.reload();
                    } else {
                        console.error('Erreur lors de la mise à jour du pays');
                    }
                })
                .catch(error => console.error('Erreur:', error));
        }
    };
}
